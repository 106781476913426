
import React, { useEffect } from 'react'
import '../styles/css/Main.css';
import anime from 'animejs';
import { Container, Grid, List, ListItemButton } from '@mui/material';
import '../styles/css/NavBar.css';
import Typed from 'typed.js';


function NavBar() {
  useEffect(() => {
    anime({
      targets: '.animate-nav',
      translateY: ['-200%','0'],
      duration: 500,
      easing: 'easeInOutQuad',
      delay: function(el, i, l) {
        return i * 100;
      },
      endDelay: function(el, i, l) {
        return (l - i) * 100;
      }
    });
  }, []);
  const welcome = React.useRef(null);
  React.useEffect(() => {
      const typed = new Typed(welcome.current, {
        strings: ['ortfolio'],
        typeSpeed: 70,
        backSpeed: 30,
        loop: true
      });
  
      return () => {
        // Destroy Typed instance during cleanup to stop animation
        typed.destroy();
      };
    }, []);
  return (
    // <div className="NavBar navbar-font" data-scroll-section>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between">
         <Grid item alignItems="flex-start">
          <h4 className="animate-nav">p<span ref={welcome}></span></h4>
         </Grid>
         <Grid item alignItems="flex-end ">
        
         </Grid>  
        </Grid>
         
      </Container>
        
    // </div>
  )
}

export default NavBar