
import { Container, Divider, Grid, Grid2, Stack } from '@mui/material'
import React from 'react'
import ProjectList from './ProjectList'
import Aipdf from "../../styles/files/AI.pdf";
function NewProjects() {
  return (
    <div>
        <Container maxWidth="md">
            <h1>projects</h1>
        <Stack 
            spacing={2}
            divider={<Divider orientation="horizontal" flexItem/>}
            >
            {/* <h3>V Street Trading</h3>
            <p>A virtual stock market game to practice & experiement with different trading strategies on U.S., FOREX, & Crypto Markets</p> */}
            <ProjectList name="V Street Trading" description="A virtual stock market game to practice & experiement with different trading strategies on U.S., FOREX, & Crypto Markets"/>
            <ProjectList name="AI Age Predictor" description="AI Project that predicts the age of a person given an image input." link={Aipdf}/>
            <ProjectList name="Lumina Archives" description="Library database application." link="https://github.com/kelanwu17/COSC3380-Library-Database"/>
            <ProjectList name="Volunteer Hub" description="Full-stack application for users finding/registering for local volunteering opprotunities." link="https://github.com/kelanwu17/COSC-4353-Project"/>
            <ProjectList name="Simply State Farm" description="HackUTD X 2023 Project. Application that streamlines the process of receiving an insurance quote." link="https://github.com/Aalhad-Pathare/HackUTD-X"/>
            <ProjectList name="NBA ROTY Prediction Model" description="DSML project predicting every recepient of the ROTY award from 2010 - present. Trained on data from 1980 - 2009 season." link="https://github.com/kelanwu17/predicting-roty"/>
            <ProjectList name="Resource Visualization" description="Petroleum analytics application. UH CodeRed Odyssesy 2022 project." link="https://github.com/kelanwu17/codered-hackathon"/>
            <ProjectList name="Stat Padder" description="Application where users can search and retrieve data for every player who has stepped on the NBA court." link="https://github.com/kelanwu17/Stat-Padder"/>
        </Stack>
        </Container>
        
    
        
           
    </div>
  )
}

export default NewProjects