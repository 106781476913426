import { Box } from "@mui/material";
import React from "react";

function ProjectList({ name, description, link }) {
  return (
    <div>
      <a href={link} target="_blank" style={{color:'inherit',textDecoration:'none'}}>
        <Box
          sx={{ borderRight:"solid 0.5px", borderLeft:"solid 0.5px", borderColor:'white', padding: "10px" ,
            '&:hover': {
              borderColor:'black',
            }
          }}
        >
          <h4 >{name}</h4>
          <p>{description}</p>
        </Box>
      </a>
    </div>
  );
}

export default ProjectList;
